/* Variables from brand tokes (sent by Rich) */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: "Plus Jakarta Sans", Arial, Helvetica, sans-serif;
  vertical-align: baseline;
  color: #211F28;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  font: 100%;
  margin: 0;
  padding: 0;
}

a {
  color: #512eab;
  font-style: normal;
  font-weight: 600;
  line-height: inherit;
  text-decoration: none;
  border-bottom: 2px solid #df41cf;
}

.page-wrapper {
  display: grid;
  grid-template-columns: 17rem auto;
  grid-template-rows: 4rem auto 4rem;
}
.page-wrapper .app-header {
  grid-column: 1/span 2;
  grid-row: 1;
}
.page-wrapper .sidebar {
  grid-column: 1;
  grid-row: 2;
}
.page-wrapper main {
  grid-column: 2;
  grid-row: 2;
  padding: 2rem;
  background: #fff;
  min-height: calc(100vh - 4rem);
}
.page-wrapper .footer {
  grid-row: 3;
  grid-column: 1/span 2;
}

/* Candidate dashboard */
.candidate-dashboard {
  background: #F3F2FF;
  position: relative;
}
.candidate-dashboard:before {
  content: "";
  background: #0D004D url(../images/bg_triplewave.png) no-repeat right center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 29rem;
  z-index: 1;
}
.candidate-dashboard main {
  min-height: calc(100vh - 4rem);
  max-width: 78rem;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  z-index: 2;
}
.candidate-dashboard h1 {
  color: #fff;
  font-size: 4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.125;
  margin-bottom: 1rem;
}
.candidate-dashboard p.page-prompt {
  color: #fff;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 3.5rem;
  max-width: 600px;
}

/* Inputs ---*/
input, textarea {
  border-radius: 0.5rem;
  border: 1px solid #BCB9CC;
  padding: 0.75rem;
  font-size: 1rem;
}
input:focus, textarea:focus {
  border-color: #56E2E1;
  outline: 1px solid #56E2E1;
}

select {
  border-radius: 0.25rem;
  border: 1px solid #DAD8E3;
  padding: 0.5rem 1rem;
}

.input-wrapper {
  border-radius: 0.5rem;
  border: 1px solid #BCB9CC;
  background: #fff;
  padding: 0.875rem 1rem 1rem;
  height: 42px;
  display: flex;
  flex-direction: column;
  gap: 0.25rrem;
}
.input-wrapper:focus-within {
  outline: 1px solid #0D004D;
  border-color: #0D004D;
}
.input-wrapper > input {
  border: 0;
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  padding: 0.25rem 0 0 0;
}
.input-wrapper > input:focus {
  outline: none;
}
.input-wrapper label {
  color: #211F28;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* Back Btn */
.back-btn {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border: 0;
  padding: 0;
  color: #0D004D;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: 1.75rem;
}

/* Checkobox --- */
.checkbox-container {
  display: flex;
  align-items: center;
  gap: 0.875rem;
}
.checkbox-container input {
  -moz-appearance: none;
       appearance: none;
  -webkit-appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 4px;
  border: 2px solid #0D004D;
  background: #fff;
}
.checkbox-container input:checked {
  background: #0D004D url(../images/icon_check.png) no-repeat center center;
}

a.send-invite {
  display: inline-block;
  white-space: nowrap;
  width: 85px;
  overflow: hidden;
  transition: width linear 0.3s;
  padding-bottom: 0.25rem;
}
a.send-invite svg {
  opacity: 0;
  transition: opacity linear 0.3s;
}
a.send-invite:hover {
  width: 102px;
}
a.send-invite:hover svg {
  opacity: 1;
}

.app-header {
  background: #0D004D;
  color: #fff;
  padding: 0.625rem 1.25rem;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 5;
  /* Variants */
}
.app-header a {
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: 0;
}
.app-header .logo-text {
  font-size: 1.25rem;
  color: #AD92F5;
}
.app-header .right {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.app-header.gradient {
  background: linear-gradient(0, rgba(13, 0, 77, 0) 2.8%, #0d004d 100%);
}
.app-header.light {
  background: #fff;
  color: #0D004D;
}
.app-header.light a,
.app-header.light .logo-text {
  color: #0D004D;
}
.app-header.transparent {
  background: transparent;
}
.app-header .stepper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn {
  border-radius: 100px;
  background: transparent;
  border-width: 2px;
  border-style: solid;
  padding: 1rem 2rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  cursor: pointer;
  /* color variations */
  /* Size variations */
  /* Icon position variation */
}
.btn.primary {
  background: #512EAB;
  color: #fff;
  border-color: #512EAB;
}
.btn.primary svg {
  color: #fff;
}
.btn.primary:hover {
  border-color: #693cde;
  background-color: #693cde;
}
.btn.secondary {
  background-color: transparent;
  color: #0D004D;
  border-color: #0D004D;
}
.btn.secondary:hover {
  background-color: white;
}
.btn.secondary-inverse {
  border-color: #fff;
  color: #fff;
}
.btn.tertiary {
  background-color: #FFCE00;
  border-color: #FFCE00;
  color: #0D004D;
}
.btn.tertiary:hover {
  border-color: #FFE680;
  background-color: #FFE680;
}
.btn.purple {
  background-color: #5B5EA3;
  border-color: #5B5EA3;
  color: #fff;
}
.btn.icon {
  border: 0;
  padding: 0.5rem;
}
.btn:disabled {
  background-color: #E1DFEA;
  border-color: #E1DFEA;
  color: #848192;
  cursor: default;
}
.btn.small {
  padding: 0.5rem 1rem;
}
.btn.after {
  flex-direction: row-reverse;
}

.radio-container {
  display: flex;
  flex-direction: column;
}
.radio-container label {
  display: flex;
  align-items: center;
  padding: 12px 0;
  cursor: pointer;
}
.radio-container input[type=radio] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 24px;
  height: 24px;
  background-color: white;
  border: 2px solid #bcb9cc;
  border-radius: 50%;
  position: relative;
  margin-right: 12px;
  cursor: pointer;
}
.radio-container input[type=radio]:checked {
  border: 2px solid #512eab;
  background-color: white;
}
.radio-container input[type=radio]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  background-color: #512eab;
  border-radius: 50%;
}

.stepper {
  display: flex;
  list-style-type: none;
  justify-content: center;
  align-items: center;
}
.stepper * {
  box-sizing: border-box;
}
.stepper li {
  color: #5B5EA3;
  display: grid;
  grid-template-columns: 3.3rem 2.5rem 3.3rem;
  grid-template-rows: 1fr 1fr;
  align-items: center;
}
.stepper li .step-status {
  grid-column: 2;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.5rem;
  border-radius: 50%;
  border: 2px solid #5B5EA3;
  display: flex;
  align-items: center;
  justify-content: center;
}
.stepper li .step-status svg {
  color: #5B5EA3;
  stroke: #5B5EA3;
}
.stepper li::before {
  grid-column: 1;
  content: "";
  height: 2px;
  width: 3.3rem;
  background-color: #5B5EA3;
}
.stepper li::after {
  grid-column: 3;
  content: "";
  height: 2px;
  width: 3.3rem;
  background-color: #5B5EA3;
}
.stepper li .step-name {
  grid-row: 2;
  grid-column: 1/span 3;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.33;
  color: #5B5EA3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.stepper li.completed {
  color: #fff;
}
.stepper li.completed::before, .stepper li.completed:after {
  background-color: #fff;
}
.stepper li.completed .step-status {
  border-color: #fff;
  background-color: #fff;
}
.stepper li.completed .step-name {
  color: #fff;
}
.stepper li.current {
  color: #fff;
}
.stepper li.current::before {
  background-color: #fff;
}
.stepper li.current .step-status {
  border-color: #fff;
}
.stepper li.current .step-status svg {
  stroke: #fff;
  stroke-width: 1px;
  fill: #fff;
}
.stepper li.current .step-name {
  color: #fff;
}
.stepper li:first-child:before {
  display: none;
}
.stepper li:last-child::after {
  display: none;
}
.stepper.dark li {
  color: #BCB9CC;
}
.stepper.dark li .step-status {
  border-color: #BCB9CC;
}
.stepper.dark li .step-status svg {
  color: #BCB9CC;
  stroke: #BCB9CC;
}
.stepper.dark li::before, .stepper.dark li::after {
  background-color: #BCB9CC;
}
.stepper.dark li.completed {
  color: #0D004D;
}
.stepper.dark li.completed::before, .stepper.dark li.completed:after {
  background-color: #0D004D;
}
.stepper.dark li.completed .step-status {
  border-color: #0D004D;
  background-color: #0D004D;
}
.stepper.dark li.completed .step-name {
  color: #0D004D;
}
.stepper.dark li.current {
  color: #0D004D;
}
.stepper.dark li.current::before {
  background-color: #0D004D;
}
.stepper.dark li.current .step-status {
  border-color: #0D004D;
}
.stepper.dark li.current .step-status svg {
  stroke: #0D004D;
  fill: #0D004D;
}
.stepper.dark li.current .step-name {
  color: #0D004D;
}

.signin-page {
  padding: 8rem 0 0;
  background-color: #0D004D;
  background-image: url(../images/background/login_top.png), url(../images/background/login_bottom.png), url(../images/bg_wave_texture.png);
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: center -10%, center bottom, right top;
  background-size: contain, contain, contain;
}
.signin-page .login-container {
  max-width: 1100px;
  margin: 0 auto 20rem;
  display: flex;
  justify-content: space-between;
}
.signin-page .login-container .branding svg {
  margin-bottom: 2.5rem;
}
.signin-page .login-container .branding p {
  color: #fff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33;
  max-width: 25rem;
}
.signin-page .login-container form.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 500px;
  background: #fff;
  border-radius: 0.5rem;
  border: solid black 1px;
  padding: 2rem;
}
.signin-page .login-container form.container .header {
  display: flex;
  width: 100%;
  align-items: center;
}
.signin-page .login-container form.container .header h3 {
  color: #05001c;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33;
}
.signin-page .login-container form.container .question-icon {
  margin-left: auto;
}
.signin-page .login-container form.container .item {
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
}
.signin-page .login-container form.container .item .asterick-style {
  color: #df41cf !important;
}
.signin-page .login-container form.container .page-link {
  color: #512EAB;
  font-weight: 800;
  opacity: 0.7;
}
.signin-page .login-container form.container .page-link.sentence-connector {
  margin-left: 5px; /* Adjust space as needed */
}
.signin-page .login-container form.container a.page-link {
  font-weight: 800;
}
.signin-page .login-container form.container .custom-link {
  border-bottom: none;
  text-decoration: none !important; /* Removes the default underline */
  cursor: pointer;
  position: relative; /* Allows for positioning of the custom underline */
  padding-bottom: 15px;
}
.signin-page .login-container form.container .custom-link {
  color: #512eab;
  font-style: normal;
  font-weight: 600;
  line-height: inherit;
  text-decoration: none;
  border-bottom: 2px solid #df41cf;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  transition: width linear 0.3s;
  padding-bottom: 0.25rem;
}
.signin-page .login-container form.container .custom-link svg {
  opacity: 0;
  transition: opacity linear 0.3s;
}
.signin-page .login-container form.container .custom-link:hover {
  width: 220px;
}
.signin-page .login-container form.container .custom-link:hover svg {
  opacity: 1;
}
.signin-page .login-container form.container .custom-link.create-account {
  width: 145px;
}
.signin-page .login-container form.container .custom-link.create-account:hover {
  width: 158px;
}
.signin-page .login-container form.container .custom-link.have-account {
  width: 194px;
}
.signin-page .login-container form.container .custom-link.have-account:hover {
  width: 208px;
}
.signin-page .login-container form.container hr {
  width: 100%;
  /* Full width */
  height: 2px;
  /* Set a height for the line */
  background-color: #E1DFEA;
  /* Color of the line */
  border: none;
  /* Remove the default border */
}
.signin-page .login-container form.container .create-account-message {
  display: flex;
  justify-content: center;
}
.signin-page .login-container form.container .form-input input {
  padding: 1rem;
  position: relative;
  font-size: 1rem;
  width: 100%;
  height: 2rem;
  border: 1px solid #BCB9CC;
}
.signin-page .login-container form.container .form-input input::-moz-placeholder {
  position: absolute;
  top: 8px;
  font-size: 0.875rem; /* Optional, for consistent styling */
}
.signin-page .login-container form.container .form-input input::placeholder {
  position: absolute;
  top: 8px;
  font-size: 0.875rem; /* Optional, for consistent styling */
}
.signin-page .login-container form.container .form-input input.invalid {
  border-color: red;
}
.signin-page .login-container form.container .form-input .asterick {
  position: "relative";
  display: "inline-block";
}
.signin-page footer {
  margin-top: 11.875rem;
}

.timer small {
  display: block;
  font-size: 0.75rem;
}
.timer .time {
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
  padding: 0;
  display: block;
}

.sidebar {
  padding: 1rem;
  background-color: #F3F2FF;
}
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar ul li {
  margin-bottom: 0.5rem;
}
.sidebar ul li button {
  background-color: transparent;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #656270;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  border: 0;
  width: 100%;
}
.sidebar ul li button > svg {
  fill: #656270;
}
.sidebar ul li.selected button {
  background-color: #E0DFFC;
  color: #0D004D;
}
.sidebar ul li.selected button > svg {
  fill: #0D004D;
}

.volume-control {
  background-color: #fff;
  border-radius: 100px;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
  /*input[type=range][orient=vertical] {
      writing-mode: vertical-lr;
      direction: rtl;
      appearance: none;
      width: 16px;
      vertical-align: bottom;

      &::-webkit-slider-runnable-track {
          border: none;
          width: 8px;
          border-radius: 8px;
      }

      &::-webkit-slider-thumb {
          -webkit-appearance: none;
      }
      &::-webkit-track {
          width: 100%;
          cursor: pointer;
          background: $brand_purple_950; 
          border-color: transparent;
          color: transparent;
        }

  } */
}
.volume-control label {
  position: absolute;
  top: -9999px;
  left: -9999px;
}
.volume-control div.track {
  width: 8px;
  border-radius: 8px;
  height: 4.5rem;
  background-color: #CAC9FD;
  position: relative;
}
.volume-control div.track .filled-track {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 8px;
  background-color: #0D004D;
  border-radius: 8px;
}

.voice-control {
  background-color: #fff;
  border-radius: 100px;
  padding: 1.25rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  width: 1.25rem;
}

.available-assessments {
  display: flex;
  align-items: top;
  gap: 2rem;
}
.available-assessments .card > svg {
  margin-bottom: 3rem;
}
.available-assessments .card p {
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
  margin-bottom: 2.5rem;
}
.available-assessments .card .assessment-data {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 3.5rem;
}
.available-assessments .card .assessment-data li {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.875rem;
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.available-assessments .card .assessment-data .icon-wrap {
  background-color: #F9F9FC;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.available-assessments .card button {
  margin: 0 auto;
}

.card {
  background: #fff;
  border-radius: 1rem;
  padding: 3rem;
  text-align: center;
}

.card-wrapper {
  position: relative;
  width: 33.33%;
}
.card-wrapper .status-bar {
  position: absolute;
  background-color: #E1DFEA;
  height: 12px;
  width: 100%;
  border-radius: 0 0 1rem 1rem;
  bottom: 0;
  left: 0;
  z-index: 0;
  overflow: hidden;
}
.card-wrapper .status-bar .status-progress {
  background-color: #0D004D;
  height: 100%;
  width: 50%;
  transition: width 0.3s ease;
}

.footer {
  display: flex;
  flex-direction: row;
  height: 132px;
  background-color: #05001c; /* Adjust background color as needed */
  align-items: center; /* Center content vertically */
  justify-content: space-between; /* Center content horizontally */
  color: #fff;
  padding-left: 2rem;
  padding-right: 2rem;
}
.footer p {
  color: #fff;
}
.footer.is-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
.footer .footer-list {
  display: flex; /* Aligns items horizontally */
  gap: 1.25rem; /* Adds space between items */
  list-style-type: none; /* Removes bullets */
  padding: 0; /* Removes default padding */
  margin: 0; /* Removes default margin */
}
.footer .footer-list li {
  color: #fff; /* Adjust color as needed */
  cursor: pointer;
}

.sidebar {
  padding: 1rem;
  background-color: #F3F2FF;
}
.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.sidebar ul li {
  margin-bottom: 0.5rem;
}
.sidebar ul li button {
  background-color: transparent;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #656270;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  border: 0;
  width: 100%;
}
.sidebar ul li button > svg {
  fill: #656270;
}
.sidebar ul li.selected button {
  background-color: #E0DFFC;
  color: #0D004D;
}
.sidebar ul li.selected button > svg {
  fill: #0D004D;
}

.select-wrapper {
  display: flex;
  align-items: center;
  margin: 2.75rem 0 2rem 0;
}
.select-wrapper label {
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  margin-right: 0.75rem;
}
.select-wrapper select {
  border-radius: 4px;
  border: 1px solid #DAD8E3;
  background: #fff;
  width: 12.5rem;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-right: 3rem;
  color: #060609;
  font-size: 1rem;
  font-weight: 400;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: url(../images/icon_dropdown.png) no-repeat calc(100% - 0.5rem) center;
}
.select-wrapper select option {
  padding: 0.5rem;
  color: #060609;
  font-size: 1rem;
  font-weight: 300;
}

.placeholder-element {
  border-radius: 0.8vh;
  border: solid 1px;
}

.recruiter-dashboard section.title {
  display: flex;
  justify-content: space-between;
}
.recruiter-dashboard section.title h1 {
  color: #060609;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
}
.recruiter-dashboard section.select-container {
  display: flex;
  flex-direction: row;
}
.recruiter-dashboard table {
  width: 100%;
  border-collapse: collapse;
}
.recruiter-dashboard table.updated {
  width: calc(100% + 4rem);
  margin: 0 -2rem;
}
.recruiter-dashboard table.updated thead th:first-child {
  padding-left: 2rem;
}
.recruiter-dashboard table.updated thead th:last-child {
  padding-right: 2rem;
}
.recruiter-dashboard table.updated tbody tr {
  background-color: #F9F9FC;
}
.recruiter-dashboard table.updated tbody tr.updated {
  background-color: #fff;
  box-shadow: 0px -25px 16px -20px rgba(0, 0, 0, 0.05), 0px 25px 16px -20px rgba(0, 0, 0, 0.05);
}
.recruiter-dashboard table.updated tbody tr.updated td:first-child {
  position: relative;
}
.recruiter-dashboard table.updated tbody tr.updated td:first-child:before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #A2EFEF;
  position: absolute;
  left: 1rem;
  top: 50%;
  margin-top: -5px;
}
.recruiter-dashboard table.updated tbody tr.updated + tr {
  box-shadow: 0px -25px 16px -20px rgba(0, 0, 0, 0.05), inset 0px 25px 16px -20px rgba(0, 0, 0, 0.05);
}
.recruiter-dashboard table.updated tbody td:first-child {
  padding-left: 2rem;
}
.recruiter-dashboard table.updated tbody td:last-child {
  padding-right: 2rem;
}
.recruiter-dashboard table thead th {
  width: 20%;
  padding: 1rem;
  border-bottom: 1px solid #E8E7EF;
  color: #060609;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.42;
  text-align: left;
}
.recruiter-dashboard table thead th.first-column {
  width: 30%;
}
.recruiter-dashboard table thead th.role-fit-head {
  width: 10%;
}
.recruiter-dashboard table tbody tr {
  background-color: #fff;
  transition: background-color linear 0.3s;
}
.recruiter-dashboard table tbody tr:hover {
  background-color: #FBFAFF;
}
.recruiter-dashboard table tbody td {
  align-items: center;
  vertical-align: middle;
  padding: 1.85rem 1rem;
  border-bottom: 1px solid #E8E7EF;
}
.recruiter-dashboard table tbody td:first-child {
  cursor: pointer;
}
.recruiter-dashboard table tbody td p {
  color: #211F28;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}
.recruiter-dashboard table tbody td .job-title {
  color: #060609;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.4;
  margin-bottom: 2px;
}
.recruiter-dashboard table tbody td .posting-date {
  font-size: 0.875rem;
}
.recruiter-dashboard table tbody td .bold-text {
  color: #060609;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 4px;
  line-height: 1.4;
}
.recruiter-dashboard table tbody tr.user-profile img {
  float: left;
  margin-right: 0.75rem;
  width: 2.5rem;
  height: 2.5rem;
}
.recruiter-dashboard table tbody tr.user-profile h3 {
  color: #060609;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75;
}
.recruiter-dashboard table tbody td.badges div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.recruiter-dashboard table tbody td.badges div img {
  width: 3rem;
  height: 3rem;
  margin: 0;
}

.role-fit {
  color: #060609;
  font-size: 0.875rem;
  font-weight: 400;
  text-transform: capitalize;
}
.role-fit span {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.25rem;
  background-color: #211F28;
}
.role-fit span.high {
  background-color: #2eab56;
}
.role-fit span.medium {
  background-color: #e2c039;
}
.role-fit span.low {
  background-color: #bf3030;
}

.skill-tags {
  padding: 0;
}
.skill-tags div {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.skill-tags div span {
  padding: 0.25rem 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #E8E7EF;
  color: #060609;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.33;
}

.modal:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1;
}
.modal .modal-body {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 512px;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 2.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  z-index: 2;
}
.modal .modal-body h2 {
  color: #060609;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33;
}
.modal .modal-body h3 {
  color: #060609;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.4;
}
.modal .modal-body .btn {
  align-self: center;
}
.modal .modal-body .close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.modal .modal-body .close-btn:hover > svg {
  fill: #5B5EA3;
}

.progress-bar {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  max-width: 210px;
  height: 12px;
  margin: 0.75rem 0;
  border-radius: 100px;
  background-color: #E1DFEA;
}
.progress-bar .progress-step {
  flex: 1;
  height: 100%;
  background-color: #E1DFEA;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  transition: background-color 0.3s ease;
}
.progress-bar .progress-step.completed {
  max-width: 210px;
  background-color: #0D004D;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}
.progress-bar .progress-fill {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  background-color: #0D004D;
  transition: width 0.3s ease;
  z-index: 1;
}
.progress-bar .progress-segment {
  position: absolute;
  top: 0;
  height: 100%;
  width: 3px;
  background-color: #FBFAFF;
  z-index: 1;
}
.progress-bar .progress-segment.first-step {
  left: 33.33%;
}
.progress-bar .progress-segment.second-step {
  left: 66.66%;
}

.email-wrapper {
  display: grid;
  grid-template-columns: 254px auto 54px;
  max-width: 1440px;
  border-right: 1px solid #EDEFF1;
}
.email-wrapper header {
  grid-column: 1/span 3;
  border-bottom: 1px solid #EDEFF1;
}
.email-wrapper .email-hero {
  background: #0D004D url(../images/email/hero.png) no-repeat right center;
  box-sizing: border-box;
  padding: 1.5rem;
  color: #fff;
  height: 337px;
  width: 100%;
}
.email-wrapper .email-hero .full-logo {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  margin-bottom: 2.75rem;
}
.email-wrapper .email-hero .full-logo span {
  color: #AD92F5;
  font-size: 1.25rem;
}
.email-wrapper .email-hero p {
  color: #fff;
  margin-left: 1.5rem;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.55;
}
.email-wrapper .email-hero p.small {
  color: #DAD8E3;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
  margin-bottom: 1.5rem;
}
.email-wrapper .email-hero h1 {
  color: #fff;
  margin-left: 1.5rem;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.25;
  margin-bottom: 1rem;
}
.email-wrapper .email-body {
  background-color: #FBFAFF;
  padding: 3rem;
}
.email-wrapper .email-body h2 {
  color: #020917;
  font-family: "Open Sans";
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.email-wrapper .email-body p {
  color: #020917;
  font-family: "Open Sans";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 2rem;
}
.email-wrapper .email-body .action-bar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  position: fixed;
  top: 2rem;
  left: 50%;
  margin-left: -10rem;
  min-width: 20rem;
  max-width: 25rem;
  padding: 2rem;
  background: #fff;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 20;
}
.alert.animate__animated.animate__fadeInDown {
  animation-duration: 500ms;
}
.alert .close-btn {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0;
  background: transparent;
  padding: 0;
  cursor: pointer;
}
.alert > p {
  color: #060609;
}
.alert.success {
  background-color: #A2EFEF;
}

.assessment-wrapper {
  background-color: #F3F2FF;
  min-height: 100vh;
  padding-bottom: 2rem;
}
.assessment-wrapper main {
  padding: 4rem;
}
.assessment-wrapper .instructions {
  max-width: 800px;
  margin: 0 auto;
}
.assessment-wrapper .instructions h1 {
  color: #060609;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 2rem;
}
.assessment-wrapper .instructions h2 {
  color: #060609;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33;
  margin: 2rem 0 1rem;
}
.assessment-wrapper .instructions p {
  margin-bottom: 1.5rem;
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.assessment-wrapper .instructions ul {
  margin-bottom: 1.5rem;
}
.assessment-wrapper .instructions ul li {
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.assessment-wrapper .assessment-question {
  max-width: 800px;
  margin: 0 auto;
}
.assessment-wrapper .assessment-question h1 {
  color: #060609;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 2rem;
}
.assessment-wrapper .assessment-question h2 {
  margin-bottom: 1rem;
}
.assessment-wrapper .assessment-question p {
  margin-bottom: 1.5rem;
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.assessment-wrapper .assessment-question textarea {
  width: 100%;
  min-height: 12rem;
  font-family: "Plus Jakarta Sans", Arial, Helvetica, sans-serif;
}
.assessment-wrapper .assessment-question .item-details {
  display: flex;
  gap: 1rem;
  color: #BCB9CC;
  margin-bottom: 2rem;
}
.assessment-wrapper .assessment-question .item-details span {
  color: #211F28;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42;
}
.assessment-wrapper .assessment-question .reading-items {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.assessment-wrapper .assessment-question .reading-items li {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-bottom: 1.5rem;
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
.assessment-wrapper .assessment-question .reading-items li.selected {
  font-weight: 600;
}
.assessment-wrapper .assessment-question .listen-repeat {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}
.assessment-wrapper .assessment-question .listen-repeat p {
  margin-bottom: 0.5rem;
}
.assessment-wrapper .assessment-question .listen-repeat div:first-child {
  text-align: left;
}
.assessment-wrapper .assessment-question .listen-repeat div:last-child {
  text-align: right;
}
.assessment-wrapper .assessment-question .listen-repeat .listen {
  background-color: #D8D6FF;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 200px;
}
.assessment-wrapper .assessment-question .listen-repeat .repeat {
  background-color: #A2EFEF;
  border: 1px solid #56E2E1;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 200px;
}
.assessment-wrapper .assessment-question .listen-repeat .repeat > img {
  width: 100%;
  height: auto;
  /*animation: listening linear 3s; */
}
.assessment-wrapper .assessment-question .complete-sentence {
  background-color: #fff;
  border-radius: 16px;
  padding: 1rem;
}
.assessment-wrapper .assessment-question .complete-sentence p {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.assessment-wrapper .assessment-question .complete-sentence .inline-text {
  border-radius: 0.75rem;
  padding: 0.75rem;
  font-size: 1rem;
  font-family: "Plus Jakarta Sans", Arial, Helvetica, sans-serif;
}
.assessment-wrapper .assessment-complete {
  max-width: 350px;
  margin: 0 auto;
  text-align: center;
}
.assessment-wrapper .assessment-complete img {
  margin-bottom: 1.5rem;
}
.assessment-wrapper .assessment-complete h1 {
  text-align: center;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 1.5rem;
}
.assessment-wrapper .assessment-complete h2 {
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.33;
  margin-bottom: 1rem;
}
.assessment-wrapper .assessment-complete p {
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  margin: 0;
}
.assessment-wrapper .controls {
  position: absolute;
  right: 2rem;
  top: 8rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.assessment-wrapper footer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 1rem;
  background-color: rgba(243, 242, 255, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
}

@keyframes listening {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-10%);
  }
}
.accordion {
  background-color: #fff;
  border-radius: 1rem;
  margin-bottom: 0.5rem;
}
.accordion .accordion-title {
  padding: 1.25rem;
  color: #060609;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion .accordion-title p {
  margin: 0;
}
.accordion .accordion-title svg {
  transition: transform ease-out 0.3s;
}
.accordion .accordion-body {
  max-height: 0;
  overflow: hidden;
  padding: 0 1.25rem;
  transition: all ease-out 0.3s;
}
.accordion .accordion-body p {
  margin: 0;
}
.accordion.open .accordion-title p {
  font-weight: 600;
}
.accordion.open .accordion-title > svg {
  rotate: -180deg;
}
.accordion.open .accordion-body {
  max-height: 100%;
  padding: 0 1.25rem 1.25rem;
  overflow: visible;
}

.score-chart {
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
}
.score-chart .chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}
.score-chart svg {
  transform: rotate(90deg);
}
.score-chart .background {
  stroke: #E1DFEA;
  fill: transparent;
}
.score-chart .progress {
  stroke: #0D004D;
  fill: transparent;
  transform: scaleX(-1);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 1s ease;
}
.score-chart .score-percent {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #000;
  font-size: 1.25rem;
  font-weight: 600;
}
.score-chart .score-label {
  color: #060609;
  font-size: 1.25rem;
  font-weight: 600;
  margin-left: 1.5rem;
}

.performance-container {
  margin-left: 9em;
}
.performance-container .performance-bar {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}
.performance-container label {
  width: 9.56rem;
  color: #060609;
  font-size: 1.125rem;
  font-weight: 600;
}
.performance-container .bar-container {
  position: relative;
  width: 336px;
  height: 32px;
  background-color: #CFCDDB;
}
.performance-container .bar-container .scale-left {
  position: absolute;
  left: 7px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.125rem;
  color: #060609;
  z-index: 1;
}
.performance-container .bar-container .scale-right {
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.125rem;
  color: #060609;
  z-index: 1;
}
.performance-container .bar-container .unfilled-bar {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #E8E7EF;
  overflow: hidden;
}
.performance-container .bar-container .filled-bar {
  position: absolute;
  top: 0;
  height: 100%;
  width: 5.5rem;
  background-color: #CFCDDB;
  transform-origin: left;
  animation: fillAnimation 1s ease-in-out forwards;
}
.performance-container .bar-container .marker-bar {
  position: absolute;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #0D004D;
  transform-origin: left;
  animation: markerAnimation 1s ease-in-out forwards;
}
.performance-container .bar-container .icon {
  position: absolute;
  top: -20%;
  transform: translateY(-50%);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.performance-container p {
  color: #060609;
  margin-left: 2rem;
  font-size: 1.125rem;
  font-weight: 600;
}
@keyframes fillAnimation {
  0% {
    left: 0;
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes markerAnimation {
  0% {
    left: 0;
  }
  100% {
    transform: scaleX(1);
  }
}

.recruiter-view-result section.page-head {
  display: flex;
  justify-content: space-between;
}
.recruiter-view-result section.page-head .user-profile {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.recruiter-view-result section.page-head .user-profile img {
  width: 2.5rem;
  height: 2.5rem;
}
.recruiter-view-result section.page-head .user-profile h1 {
  color: #060609;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 1.2;
}
.recruiter-view-result .job-role {
  margin-top: 0.75rem;
  margin-bottom: 2rem;
}
.recruiter-view-result .job-role h3 {
  color: #211F28;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.55;
  margin-bottom: 3px;
}
.recruiter-view-result .job-role .role-fit {
  margin-top: 0.5rem;
}
.recruiter-view-result section.candidate-assets {
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.recruiter-view-result section.candidate-assets h2 {
  color: #060609;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.33;
}
.recruiter-view-result .badges {
  display: flex;
  gap: 0.5rem;
}
.recruiter-view-result .badges img {
  width: 4rem;
  height: 4rem;
}